import APIService from '@/services/api-service'
const resource = '/job'
const subResource = 'activity'

class ActivityService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getBargeActivity (jobId, fleetId, tripNo) {
    return this.connector.get(`${resource}/${subResource}/${jobId}/${fleetId}/trip-number/${tripNo}`)
  }

  public getOtherActivity (jobId) {
    return this.connector.get(`${resource}/${subResource}/${jobId}/other`)
  }

  /* public createBargeActivity (activity: any, lines: any) {
    return this.connector.post(`${resource}/${subResource}/barge-activity/create`, { activity: activity, lines: lines })
  }

  public updateBargeActivity (activity: any, lines: any) {
    return this.connector.post(`${resource}/${subResource}/barge-activity/update`, { activity: activity, lines: lines })
  } */

  public saveBargeActivity (activity: any, lines: any) {
    return this.connector.post(`${resource}/${subResource}/barge-activity/save`, { activity: activity, lines: lines })
  }

  public changeNoOfTrips (jobId: any, bargeId: any, newNoOfTrips: any) {
    return this.connector.post(`${resource}/${subResource}/${jobId}/${bargeId}/trip-count/${newNoOfTrips}`)
  }

  public mark (activity: any, lines: any) {
    return this.connector.post(`${resource}/${subResource}/mark`, { activity: activity, lines: lines })
  }

  public saveOtherActivity (activity: any, lines: any) {
    return this.connector.post(`${resource}/${subResource}/save/other`, { activity: activity, lines: lines })
  }

  /* public completeOperation (job: any) {
    return this.connector.put(`${resource}/${subResource}/complete`, job)
  } */

  public scheduleDispatch (job: any) {
    return this.connector.post(`${resource}/${subResource}/create/schedule-dispatch`, job)
  }
}

export default new ActivityService()
