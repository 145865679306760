
import {
  Component,
  Watch,
  Vue
} from 'vue-property-decorator'
import ActivityService from '../../../../services/activity-service'
import CollectionService from '../../../../services/collection-service'
import UomJobs from '@/data/uom-jobs.json'
import {
  mask
} from 'vue-the-mask'
import {
  JobModule
} from '../../../../store/JobModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'
import {
  Printd
} from 'printd'
import Status from '@/components/Status/index.vue'
import DateHelper from '@/mixins/date-mixins'
  @Component({
    name: 'Activity',
    components: {
      Status
    },
    directives: {
      mask
    }
  })
export default class Activity extends Vue {
    protected isLoader = false
    protected isLoaderTwo = false
    protected isMarked = false
    protected isPrint = false
    public isBargeActivity = true
    protected isCreated = false
    protected isSchedule = false
    protected isComplete = false
    protected uoms = UomJobs
    protected barges: any = []
    protected tripNumbers: any = []
    protected tripNumber: any = null
    protected newNoOfTrips: any = null
    protected document: any = null
    protected operationCommencedDate: any = null
    protected filteredBarges: any = []
    public allocatedBarge: any = []
    public barge: any = {}
    protected styles = ['https://api.smc-spl-application.com/print.css']

    protected activity: any = {
      id: null,
      job_id: null,
      barge_id: null,
      barge_name: null,
      barge_code: null,
      trip_number: null,
      engine_start_date: null,
      engine_start_time: null,
      depart_from_pier_date: null,
      depart_from_pier_time: null,
      alongside_vessel_date: null,
      alongside_vessel_time: null,
      horse_connected_date: null,
      horse_connected_time: null,
      commence_loading_date: null,
      commence_loading_time: null,
      completed_loading_date: null,
      completed_loading_time: null,
      horse_disconnected_date: null,
      horse_disconnected_time: null,
      depart_from_vessel_date: null,
      depart_from_vessel_time: null,
      arrival_at_pier_date: null,
      arrival_at_pier_time: null,
      attended_date: null,
      attended_time: null,
      lines: [],
      remark: null,
      created_user: null,
      modified_user: null
    }

    $v: Vuelidate

    @Validations()
    validations = {
      activity: {
        engine_start_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        depart_from_pier_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        alongside_vessel_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        horse_connected_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        commence_loading_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        completed_loading_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        horse_disconnected_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        depart_from_vessel_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        arrival_at_pier_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        attended_time: {
          validateTime (value: any) {
            if (value === null) return true
            const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
            if (isValid) return true
            return false
          }
        },
        remark: {
          alphanum (value: any) {
            const isValid = /^[a-z\d\-_\s]+$/i.test(value)
            if (isValid) return true
            return false
          }
        }
      }
    }

    get job () {
      return JobModule.job
    }

    @Watch('barge', {
      deep: true
    })
    setTripNumbers () {
      if (Object.keys(this.barge).length !== 0) {
        this.tripNumbers = Array.from({
          length: this.barge.no_of_trips
        }, (_, i) => i + 1)
      }
    }

    created () {
      this.boot()
    }

    protected boot () {
      this.document = new Printd()
      this.operationCommencedDate = this.job.operation_commenced_date
      this.populateAllocatedBarge()
    }

    protected populateAllocatedBarge () {
      this.isLoader = true
      CollectionService.getAllocatedBarge(JobModule.job.id).then((response) => {
        this.isLoader = false
        if (response.data.length === 0) {
          this.isBargeActivity = false
          return false
        }
        response.data.forEach(barge => {
          this.allocatedBarge.push({
            value: {
              id: barge.barge_id,
              name: barge.barge_name,
              no_of_trips: barge.no_of_trips
            },
            text: barge.barge_name
          })
        })
      })
    }

    protected resetBarge () {
      this.reset(this.activity)
      this.tripNumber = null
      this.$v.$reset()
    }

    public populateBargeActivity (tripNumber: number) {
      this.isLoaderTwo = true
      this.reset(this.activity)
      ActivityService.getBargeActivity(JobModule.job.id, this.barge.id, tripNumber).then((response) => {
        this.isLoaderTwo = false
        if (response.data.message === 'EMPTY') {
          this.isCreated = false
          this.activity.lines = this.job.lines.map(line => ({
            id: null,
            service_id: line.service_id,
            service_type: line.service_type,
            quantity: 0,
            old_quantity: 0,
            unit_of_measurement: null,
            created_user: AuthModule.user.fullname,
            modified_user: AuthModule.user.fullname
          }))
          return false
        }
        this.isCreated = true
        this.activity = response.data
        this.activity.lines = response.data.lines.map(line => ({
          id: line.id,
          service_id: line.service_id,
          service_type: line.service_type,
          quantity: line.quantity,
          old_quantity: line.quantity,
          unit_of_measurement: line.unit_of_measurement,
          created_user: line.created_user,
          modified_user: line.modified_user
        }))
      })
    }

    protected isValidated () {
      this.$v.$touch()

      if (this.tripNumber === null) {
        NotifyModule.set('Trip number is empty, Please select...')
        return false
      }

      if (this.$v.$invalid) {
        NotifyModule.set('There are fields that require your attention')
        return false
      }

      return true
    }

    protected save () {
      if (!this.isValidated()) return false

      const length = this.activity.lines.filter(line => line.quantity !== null).length
      const activity = this.activity
      if (!this.isCreated) {
        activity.job_id = JobModule.job.id
        activity.barge_id = this.barge.id
        activity.barge_name = this.barge.name
        activity.trip_number = this.tripNumber
        activity.created_user = AuthModule.user.fullname
      }
      activity.is_complete = this.isComplete
      activity.operation_commenced_date = this.operationCommencedDate
      activity.modified_user = AuthModule.user.fullname
      activity.status = length >= 1 && this.activity.arrival_at_pier_date !== null && this.activity
        .arrival_at_pier_time !== null ? 'completed' : 'commenced'

      const lines = this.activity.lines
      delete activity.lines

      this.isLoaderTwo = true
      ActivityService.saveBargeActivity(activity, lines).then((response) => {
        this.isLoaderTwo = false
        this.$v.$reset()
        ToastModule.message(response.data.message)
        if (this.isComplete) JobModule.setJob(JobModule.job.id)
        this.isComplete = false
        this.populateBargeActivity(this.tripNumber)
      }).catch(error => {
        this.isComplete = false
        console.log(error)
      })
    }

    protected complete () {
      if (!this.isValidated()) return false
      if (this.operationCommencedDate === null) {
        return NotifyModule.set(
          'Operation commenced date is empty, please check...')
      }
      this.isComplete = true

      this.save()
    }

    public changeNoOfTrips () {
      if (parseInt(this.newNoOfTrips) === this.barge.no_of_trips) {
        return NotifyModule.set(
          'Already no.of trips equals to ' + this.newNoOfTrips)
      }
      ActivityService.changeNoOfTrips(JobModule.job.id, this.barge.id, this.newNoOfTrips).then((response) => {
        ToastModule.message(response.data.message)
        this.barge.no_of_trips = this.newNoOfTrips
        if (this.tripNumber !== null) {
          this.resetBarge()
        }
      }).catch(error => {
        console.log(error)
      })
    }

    public print () {
      this.isPrint = true
      this.$nextTick(() => {
        this.document.print(document.getElementById('printWorkDoneCertificate'), this.styles)
      })
    }

    /* protected schedule (type: string) {
      const job = {
        job_id: this.job.id,
        type: type,
        added_date: DateHelper.today('dd/mm/yyyy'),
        created_user: AuthModule.user.fullname,
        modified_user: AuthModule.user.fullname
      }
      CollectionService.scheduleOperation(job).then((response) => {
        ToastModule.message(response.data.message)
        JobModule.setJob(JobModule.job.id)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    } */

    public reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

